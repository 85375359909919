import {
  Box,
  Container,
  Flex,
  Heading,
  IconButton,
  Img,
  Text,
} from '@chakra-ui/react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { FaLinkedin, FaYoutube } from 'react-icons/fa';

import SubscribeCard from './common/Subscribecard';

const Footer: React.FC = () => {
  const router = useRouter();
  return (
    <Box
      w="100%"
      h="100%"
      as="footer"
      pt="80px"
      pb="58px"
      pl={{ xs: '10px', sm: '30px', md: '50px', lg: '70px', xl: '88px' }}
      pr={{ xs: '10px', sm: '30px', md: '80px', lg: '80px', xl: '105px' }}
      pos="relative"
      bottom="0"
      left="0"
      right="0"
      bgColor="#F7F7FB"
    >
      {router.route !== '/corporate-governance' &&
        router.route !== '/green-future' &&
        router.route !== '/startup-mahakumbh' && <SubscribeCard />}
      <Container
        as="main"
        display="grid"
        gridTemplateColumns={
          router.route !== '/vc-101'
            ? {
                xs: '1fr',
                md: '1fr 1fr 1fr',
                lg: '1fr 1fr 1fr 1fr',
                xl: '1fr 1fr 1fr 1fr 1fr',
              }
            : {
                xs: '1fr',
                md: '1fr 1fr 1fr',
                lg: '1fr 1fr 1fr 1fr',
              }
        }
        rowGap="20px"
        maxW="container.xl"
        mb="49px"
      >
        <Flex
          justify={{ xs: 'center', md: 'flex-start' }}
          mx={{ xs: 'auto', md: '0' }}
          h="110px"
          width={{ xs: '160px', md: '200px' }}
        >
          <Image
            src="/png/IVCA-Logo.png"
            alt="IVCA logo"
            aria-label="IVCA logo"
            width={240}
            height={97}
            style={{ objectFit: 'contain' }}
          />
        </Flex>
        <Flex
          as="article"
          mt={{ xs: '2rem', md: '0' }}
          pl={{ md: '3rem' }}
          w={{ xs: '100%', lg: '190px', xl: '252px' }}
          flexDir="column"
          textAlign="center"
          alignItems="center"
        >
          <Heading
            textAlign="left"
            variant="footerTitle"
            mb={{ xs: '10px', md: '25px' }}
          >
            Website
          </Heading>
          <Flex
            gap="10px"
            mb="13px"
            flexDir="column"
            alignItems={{ xs: 'center', md: 'flex-start' }}
          >
            <Link href="/affiliations">
              <Text
                color="text.mediumBlack"
                fontSize="15px"
                textAlign="left"
                cursor="pointer"
                _hover={{ color: 'primary.500' }}
              >
                Affiliations
              </Text>
            </Link>
            <Link href="/disclaimer">
              <Text
                color="text.mediumBlack"
                fontSize="15px"
                textAlign="left"
                cursor="pointer"
                _hover={{ color: 'primary.500' }}
              >
                Disclaimer
              </Text>
            </Link>
            <Link href="/tc">
              <Text
                color="text.mediumBlack"
                fontSize="15px"
                textAlign="left"
                cursor="pointer"
                _hover={{ color: 'primary.500' }}
              >
                T&C
              </Text>
            </Link>
            <Link href="/privacy-policy">
              <Text
                color="text.mediumBlack"
                fontSize="15px"
                textAlign="left"
                cursor="pointer"
                _hover={{ color: 'primary.500' }}
              >
                Privacy Policy
              </Text>
            </Link>
            <Link href="/refund-policy">
              <Text
                color="text.mediumBlack"
                fontSize="15px"
                textAlign="left"
                cursor="pointer"
                _hover={{ color: 'primary.500' }}
              >
                Refund Policy
              </Text>
            </Link>
            <Link href="/">
              <Text
                color="text.mediumBlack"
                fontSize="15px"
                textAlign="left"
                cursor="pointer"
                _hover={{ color: 'primary.500' }}
              >
                About IVCA
              </Text>
            </Link>
          </Flex>
        </Flex>
        {router.route !== '/vc-101' && (
          <Flex
            as="article"
            mt={{ xs: '2rem', md: '0' }}
            pl={{ md: '1.5rem' }}
            w={{ xs: '100%', lg: '190px', xl: '252px' }}
            flexDir="column"
            alignItems={{ xs: 'center', md: 'flex-start' }}
            textAlign="center"
          >
            <Heading
              textAlign="left"
              variant="footerTitle"
              mb={{ xs: '10px', md: '25px' }}
            >
              Industry
            </Heading>
            <Flex
              gap="10px"
              mb="13px"
              flexDir="column"
              alignItems={{ xs: 'center', md: 'flex-start' }}
            >
              <Link href="/private-equity">
                <Text
                  color="text.mediumBlack"
                  fontSize="15px"
                  textAlign="left"
                  cursor="pointer"
                  _hover={{ color: 'primary.500' }}
                >
                  Private Equity
                </Text>
              </Link>
              <Link href="/venture-capital-2">
                <Text
                  color="text.mediumBlack"
                  fontSize="15px"
                  textAlign="left"
                  cursor="pointer"
                  _hover={{ color: 'primary.500' }}
                >
                  Venture Capital
                </Text>
              </Link>
              <Link href="/glossary">
                <Text
                  color="text.mediumBlack"
                  fontSize="15px"
                  textAlign="left"
                  cursor="pointer"
                  _hover={{ color: 'primary.500' }}
                >
                  Glossary
                </Text>
              </Link>
            </Flex>
          </Flex>
        )}
        <Flex
          as="article"
          mt={{ xs: '2rem', md: '0' }}
          pl={{ md: '1rem' }}
          w={{ xs: '100%', sm: '80%', md: '270px', lg: '240px', xl: '252px' }}
          flexDir="column"
          alignItems={{ xs: 'center', md: 'flex-start' }}
          textAlign="center"
          mx="auto"
        >
          <Heading
            textAlign="left"
            variant="footerTitle"
            mb={{ xs: '10px', md: '25px' }}
          >
            contact us
          </Heading>
          <Flex
            columnGap="20px"
            mb="13px"
            justify={{ xs: 'center', md: 'flex-start' }}
          >
            <Img
              src="/svg/mail.svg"
              alt="mail icon"
              aria-label="mail logo"
              boxSize="20px"
            />
            <Text
              color="text.mediumBlack"
              fontWeight="800"
              fontSize="15px"
              textAlign="left"
            >
              info@ivca.in
            </Text>
          </Flex>
          <Flex columnGap={{ xs: '20px', md: '20px' }} justify="center">
            <Img
              aria-label="mapPin logo"
              src="/svg/mapPin.svg"
              alt="mapPin logo"
              boxSize="20px"
            />
            <Text
              color="text.mediumBlack"
              fontSize="14px"
              fontWeight="500"
              textAlign="left"
            >
              First floor, Room 7 & 13 Innov8, Old Fort, Saket District Centre
              New Delhi - 110017
            </Text>
          </Flex>
        </Flex>
        <Flex
          mt={{ xs: '2rem', md: '0' }}
          pl={{ md: '2rem', lg: '0px', xl: '2rem' }}
          columnGap="12px"
          mx={{ xs: 'auto', md: '0' }}
        >
          {/* <Link href="https://www.facebook.com/indiavca/" target="_blank">
            <IconButton
              aria-label="fb"
              icon={<FaFacebook size="22px" color="rgba(100, 100, 140, 0.6)" />}
              variant="unstyled"
              cursor="pointer"
            />
          </Link> */}
          <Link
            href="https://in.linkedin.com/company/indian-private-equity-venture-capital-association"
            target="_blank"
          >
            <IconButton
              aria-label="linkedin"
              icon={<FaLinkedin size="22px" color="rgba(100, 100, 140, 0.6)" />}
              variant="unstyled"
              cursor="pointer"
            />
          </Link>
          <Link href="https://twitter.com/IndianVCA" target="_blank">
            <Box mr={4} mt="10px">
              <Img
                src="https://static.levocdn.com/WGLBUT82/7132271116957757172.svg"
                alt="twitter"
              />
            </Box>
          </Link>
          <Link
            href="https://www.youtube.com/channel/UCEyV1l7ncz8m9xYVXlhGEeA"
            target="_blank"
          >
            <IconButton
              aria-label="youtube"
              icon={<FaYoutube size="22px" color="rgba(100, 100, 140, 0.6)" />}
              variant="unstyled"
              cursor="pointer"
            />
          </Link>
        </Flex>
      </Container>
      <Container
        display="flex"
        maxW="container.xl"
        columnGap={{ md: '25px', lg: '35px' }}
        flexDir={{ xs: 'column', md: 'row' }}
        justifyContent={{ xs: 'center', md: 'flex-start' }}
        rowGap="20px"
        w="100%"
        alignItems="center"
        textAlign="center"
      >
        <Text variant="featureText" color="lightGreyContrast">
          Copyright &copy; {new Date().getFullYear()} IVCA. All rights reserved.
        </Text>
        <a
          rel="noopener noreferrer"
          href="https://theinternetfolks.com/"
          target="_blank"
          aria-label="The Internet Folks"
        >
          <Text
            _hover={{ textDecor: 'underline' }}
            variant="featureText"
            color="lightGreyContrast"
          >
            Managed by: The Internet Folks
          </Text>
        </a>
      </Container>
    </Box>
  );
};

export default Footer;
